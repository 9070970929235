<template>
  <b-sidebar
    v-if="show"
    id="add-new-property-sidebar"
    :visible="isAddNewPropertySidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-property-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ !isEdit ? 'Add' : 'Edit' }} Property
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-form-group label="Property Main Image">

            <al-upload
              v-if="!isEdit"
              :action="`${baseURL}/global/uploadImage`"
              :simultaneous-uploads="1"
              :max="1"
              @file-success="onMainImageUploaded"
            />
            <b-col
              v-else
              class="p-0 m-0"
            >
              <b-img
                v-bind="imageMainProps"
                :src="propertyModel[propertiesModelField.mainImage]"
              />

              <al-upload
                ref="upload"
                v-model="mainImage"
                :action="`${baseURL}/global/uploadImage`"
                :max="1"
                @file-success="onMainImageUploaded"
              >
                <al-upload-file
                  v-for="(file, i) in mainImage"
                  :key="i"
                  :file="file"
                />
                <al-upload-btn :multiple="false">
                  <div>
                    <b-button
                      v-if="mainImage.length <= 0"
                      block
                      class="mt-1"
                    >
                      Change Main Image
                    </b-button>
                  </div>
                </al-upload-btn>
              </al-upload>

            </b-col>
          </b-form-group>

          <validation-provider
            #default="validationContext"
            name="PropertyType"
            rules="required"
          >
            <b-form-group
              label="Property Type"
              label-for="PropertyType"
              :state="getValidationState(validationContext)"
            >

              <v-select
                v-model="propertyModel[propertiesModelField.type]"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                label-for="propertyType"
                placeholder="Select Property Type"
                required
                :options="propertyTypeOption"
              />
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Property Name"
            rules="required"
          >

            <b-form-group
              label="Property Name"
              label-for="nm"
            >
              <b-form-input
                v-model="propertyModel[propertiesModelField.name]"
                :state="getValidationState(validationContext)"
                trim
                type="text"
                placeholder="Property Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-if="isTypeBuilding()"
            #default="validationContext"
            name="Number of Floors"
          >

            <b-form-group label="Number of Floors">
              <b-form-input

                v-model="propertyModel[propertiesModelField.numberOfFloors]"

                :state="getValidationState(validationContext)"
                trim
                number
                type="number"
                placeholder="How many Floors exist?"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Number of Basements"
          >

            <b-form-group label="Number of Basements">
              <b-form-input

                v-model="propertyModel[propertiesModelField.numberOfBasement]"

                :state="getValidationState(validationContext)"
                trim
                type="number"
                number
                placeholder="How many Basements exist?"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <app-collapse
            accordion
            type="border"
          >
            <app-collapse-item title="Show all (optional)">
              <b-form-group
                label="Other Images"
                label-for="other-images"
              >
                <b-row v-if="!isEdit || (isEdit && (!propertyModel[propertiesModelField.otherImage] || (propertyModel[propertiesModelField.otherImage] && propertyModel[propertiesModelField.otherImage].length <= 0)))">

                  <b-col
                    cols="12"
                  >
                    <al-upload

                      v-model="otherImages"
                      :action="`${baseURL}/global/uploadImage`"
                      :simultaneous-uploads="5"
                      :max="5"
                      @file-success="onOtherImageUploaded"
                    />

                  </b-col>
                </b-row>
                <b-col
                  v-else
                  class="p-0"
                >
                  <b-img
                    v-for="(item, index) in propertyModel[propertiesModelField.otherImage]"
                    :key="index"
                    v-bind="imageMainProps"
                    :src="item"
                  />
                  <al-upload
                    v-if="propertyModel[propertiesModelField.otherImage].length < 5"
                    ref="upload"
                    v-model="otherImages"
                    :action="`${baseURL}/global/uploadImage`"
                    :max="(5 - propertyModel[propertiesModelField.otherImage])"
                    @file-success="onOtherImageUploaded"
                  >
                    <al-upload-file
                      v-for="(file, i) in otherImages"
                      :key="i"
                      :file="file"
                    />
                    <al-upload-btn :multiple="true">
                      <div>
                        <b-button
                          v-if="otherImages.length <= 0"
                          block
                          class="mt-1"
                        >
                          Change Image
                        </b-button>
                      </div>
                    </al-upload-btn>
                  </al-upload>
                </b-col>
              </b-form-group>
              <b-card class="border mb-0">
                <b-form-group>

                  <b-form-input

                    v-model="propertyModel[propertiesModelField.country]"
                    trim
                    placeholder="Country"
                  />

                </b-form-group>
                <b-form-group>
                  <b-form-input
                    v-model="propertyModel[propertiesModelField.city]"
                    trim
                    placeholder="City"
                  />
                </b-form-group>
                <b-form-group>
                  <b-form-input
                    v-model="propertyModel[propertiesModelField.location]"
                    trim
                    placeholder="Location"
                  />
                </b-form-group>
              </b-card>
            </app-collapse-item>
          </app-collapse>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="gradient-primary"
              class="mr-2"
              :disabled="isLoading"
              type="submit"
            >
              {{ isEdit ? 'Update' : 'Submit' }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              :disabled="isLoading"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCard, VBToggle, BCol, BRow, BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { mapGetters } from 'vuex'
import AlUpload from '../../components/upload/AlUpload.vue'
import AlUploadBtn from '../../components/upload/btn.vue'
import AlUploadFile from '../../components/upload/file.vue'
import { baseURL } from '@/libs/axios'
import { propertiesModelField, propertiesType } from '@/api/field_model'
import { defaultPropertyModel } from '@/api/defaultModel'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BCard,
    vSelect,
    AppCollapse,
    AppCollapseItem,
    BCol,
    BRow,
    BImg,
    AlUploadBtn,
    AlUploadFile,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    AlUpload,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  model: {
    prop: 'isAddNewPropertySidebarActive',
    event: 'update:is-add-new-property-sidebar-active',
  },
  props: {
    isAddNewPropertySidebarActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      required,
      propertyTypeOption: [
        { title: 'Individual Unit', value: propertiesType.individualUnit }, { title: 'Building complex', value: propertiesType.building },
      ],
      imageMainProps: {
        style: 'height: 140px;width:140px;',
        fluid: true,
        thumbnail: true,
      },
      mainImage: [],
      otherImages: [],
      show: true,
      baseURL,
      propertiesModelField,
    }
  },
  computed: {
    ...mapGetters('addEditProperty', [
      'isLoading',
    ]),
  },
  methods: {
    isTypeBuilding() {
      return this.propertyModel[propertiesModelField.type].value === propertiesType.building
    },
    // eslint-disable-next-line no-unused-vars
    onOtherImageUploaded(v) {
    },
    onMainImageUploaded(v) {
      this.propertyModel[propertiesModelField.mainImage] = v.response.data.url
      this.mainImage = []
    },
    reset() {
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    showToast(title, text) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'icon-alert-circle',
          text,
          variant: 'danger',
        },
      },
      {
        timeout: 2500,
        position: 'bottom-right',
      })
    },
    onSubmit() {
      const propertyModel = { ...this.propertyModel }
      propertyModel[propertiesModelField.type] = propertyModel[propertiesModelField.type].value
      const payload = {
        propertyModel,
        notify: this.showToast,
      }
      this.$store.dispatch(`addEditProperty/${!this.isEdit ? 'addProperties' : 'updateProperty'}`, payload)
        .then(() => {
          this.$emit('update:is-add-new-property-sidebar-active', false)
        })
        .catch(error => {
          this.showToast('Error', error.message)
        })
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const propertyModel = { ...defaultPropertyModel }

    const resetPropertyModelData = () => {
      propertyModel.value = JSON.parse(JSON.stringify(defaultPropertyModel))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetPropertyModelData)
    return {
      propertyModel,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style scoped>

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-property-sidebar {
  .vs__dropdown-menu {
    max-height: 450px !important;
  }

}
</style>
