<template>
  <section class="ecommerce-application cursor-pointer">
    <div class="list-view product-checkout">
      <div
        class="checkout-items"
        @click="onClick"
      >
        <b-card
          class="ecommerce-card"
          style="border: none;"
          no-body
        >
          <div class="item-img">
            <b-link>
              <b-img
                :src="image"
              />
            </b-link>
          </div>
          <b-card-body class="pt-0">
            <div class="item-name">
              <h6 class="mb-0">
                <h4 class="text-body">
                  {{ propertyModel.nm }}
                </h4>
                <span class="delivery-date pt-2">{{ desc }}</span>
              </h6>
            </div>
            <div class="card-app-design">

              <div class="design-planning-wrapper mt-1">
                <div class="design-planning">
                  <h4 class="mb-0 text-danger mb-25">
                    {{ propertyModel.numberOfOverdue }}
                  </h4>
                  <p class="card-text ">
                    Rent overdue
                  </p>

                </div>
                <div class="design-planning">
                  <h4 class="mb-0 text-warning mb-25">
                    {{ propertyModel.numberOfLeaseExpire }}
                  </h4>
                  <p class="card-text">
                    Lease Expired
                  </p>
                </div>
                <div class="design-planning">
                  <h4 class="mb-0 mb-25">
                    {{ propertyModel.numberOfUnrented }}
                  </h4>
                  <p class="card-text">
                    Unrented
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>

      </div>
    </div>
  </section>
</template>

<script>
import {
  BCard, BLink, BCardBody, BImg,
} from 'bootstrap-vue'
import { propertiesModelField, propertiesType } from '@/api/field_model'
import { noImage } from '@/assets/helpers'
import store from '@/store'

export default {
  components: {
    BCard, BLink, BCardBody, BImg,
  },
  props: {
    propertyModel: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    image() {
      return this.propertyModel[propertiesModelField.mainImage] && this.propertyModel[propertiesModelField.mainImage].length > 0 ? this.propertyModel[propertiesModelField.mainImage] : noImage
    },
    desc() {
      const { propertyModel } = this
      return propertyModel[propertiesModelField.type] === propertiesType.building ? `${propertyModel.numberOfFloorsNo} Floors, ${propertyModel.numberOfBasementsNo} Basement(s)`
        : `${propertyModel.numberOfGroundNo} Units, ${propertyModel.numberOfBasementsNo} Basement(s)`
    },
  },
  methods: {
    onClick() {
      const { propertyModel } = this
      store.commit('currentProperty/CHANGE_DATA', propertyModel)
      this.$router.push({ name: 'property', query: { propId: propertyModel.id } })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>
