<template>
  <b-sidebar
    id="more-properties-sidebar"
    :visible="isMorePropertiesSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-more-properties-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Properties
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <b-card>

        <b-overlay
          id="overlay-background"
          :show="isLoading"
          variant="blur"
          :opacity="0.5"
          blur="2px"
          rounded="sm"
        >
          <div
            v-for="(item, index) in properties"
            :key="index"
          >
            <PropertyCard :property-model="item" />
          </div>
          <b-button
            variant="primary"
            :disabled="loadNoMore"
            @click="loadMore"
          >
            {{ loadNoMore ? 'Load No more' : 'See all properties' }}
          </b-button>
        </b-overlay>
      </b-card>
    </template>

  </b-sidebar>
</template>

<script>
import {
  BSidebar, BButton, BOverlay, BCard,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import PropertyCard from './PropertyCard.vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { LogicStateStatus } from '@/libs/ajax'

export default {
  components: {
    BSidebar, PropertyCard, BButton, BOverlay, BCard,
  },
  model: {
    prop: 'isMorePropertiesSidebarActive',
    event: 'update:is-more-properties-sidebar-active',
  },
  props: {
    isMorePropertiesSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      page: 2,
    }
  },
  computed: {
    ...mapState({
      properties: state => state.home.properties,
      logicStateStatus: state => state.home.logicStateStatus,
      isLoading: state => state.home.logicStateStatus === LogicStateStatus.loading,
      loadNoMore: state => state.home.logicStateStatus === LogicStateStatus.loadNoData,
    }),
  },
  methods: {
    showToast(title, text) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'icon-alert-circle',
          text,
          variant: 'danger',
        },
      },
      {
        timeout: 2500,
        position: 'bottom-right',
      })
    },
    loadMore() {
      const payload = {
        page: this.page,
        notify: this.showToast,
      }
      this.$store.dispatch('home/getMyProperties', payload)
        .then(() => { this.page += 1 })
        .catch(error => {
          this.showToast('Error', error.message)
        })
    },
  },
}
</script>

<style lang="scss">
#more-properties-sidebar {
  width: 700px;
}
</style>
