<template>
  <section id="dashboard-analytics">
    <!-- class="match-height" -->
    <b-row>
      <b-col
        v-if="isReportAllowed"
        lg="5"
      >
        <RevenueExpenseCard />
      </b-col>
      <b-col lg="7">
        <HomeProperties />
      </b-col>
    </b-row>

  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import { kFormatter } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapState } from 'vuex'
import HomeProperties from './components/HomeProperties.vue'
import RevenueExpenseCard from './components/RevenueExpenseCard.vue'
import AuthMixin from '@/mixins/authMixin'

export default {
  components: {
    BRow,
    BCol,
    RevenueExpenseCard,
    HomeProperties,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

  },
  mixins: [AuthMixin],
  data() {
    return {
      page: 1,
    }
  },
  computed: {
    ...mapState({
      properties: state => state.home.properties,
      homeDataModel: state => state.home.homeDataModel,
      logicStateStatus: state => state.home.logicStateStatus,
    }),
  },
  mounted() {
    this.getMyProperties()
  },
  methods: {
    kFormatter,
    showToast(title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'icon-alert-circle',
          text,
          variant: 'danger',
        },
      },
      {
        timeout: 2500,
        position: 'bottom-right',
      })
    },
    getMyProperties() {
      const payload = {
        page: this.page,
        notify: this.showToast,
      }
      this.$store.dispatch('home/getMyProperties', payload)
        .then(() => { })
        .catch(error => {
          this.showToast('Error', error.message)
        })
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
