<template>
  <b-card no-body>

    <b-card-header>
      <b-card-title>Property Revenue Overview</b-card-title>
    </b-card-header>

    <b-card-body>

      <b-row>
        <vue-apex-charts
          ref="realtimeChart"
          width="500"
          type="bar"
          :options="options"
          :series="series"
        />

      </b-row>
      <hr>

      <!-- progress bar -->
      <b-row class="avg-sessions pt-50">
        <b-col
          cols="6"
          class="mb-2"
        >

          <h3
            class="mb-0 "
            style="margin-bottom: 1px !important;"
          >
            Br {{ totalMoneyOutstandingInThisMonth }}
          </h3>
          <div class="font-small-3 mb-1">
            Uncollected In Nov
          </div>

          <b-progress
            :value="`${getPercentage(totalMoneyOutstandingInThisMonth, totalMoneyCollectedInThisMonth)}`"
            max="100"
            height="10px"
          />
          <b-card-text class="mt-50">
            {{ totalMoneyCollectedInThisMonth }} collected
          </b-card-text>
        </b-col>
        <b-col
          cols="6"
          class="mb-2"
        >
          <h3
            class="mb-0 "
            style="margin-bottom: 1px !important;"
          >
            Br {{ totalMoneyOutstandingInPreviousMonth }}
          </h3>
          <div class="font-small-3 mb-1">
            Uncollected In Dec
          </div>
          <b-progress
            :value="`${getPercentage(totalMoneyOutstandingInPreviousMonth, totalMoneyCollectedInPreviousMonth)}`"
            max="100"
            height="10px"
            variant="warning"
          />
          <b-card-text class="mt-50">
            {{ totalMoneyCollectedInPreviousMonth }} collected
          </b-card-text>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
/* eslint-disable no-nested-ternary */
import {
  BCard, BRow, BCol, BCardText, BProgress, BCardHeader, BCardTitle, BCardBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    BCard, BRow, BCol, BCardText, BProgress, BCardHeader, BCardTitle, BCardBody, VueApexCharts,
  },
  data() {
    return {
      options: {
        chart: {
          id: 'vuechart-example',
        },
        colors: [$themeColors.primary, $themeColors.warning],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            // endingShape: 'rounded',
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        // grid: {
        //   padding: {
        //     top: -20,
        //     bottom: -10,
        //   },
        //   yaxis: {
        //     lines: { show: false },
        //   },
        // },
        xaxis: {
          labels: {
            style: {
              colors: '#6E6B7B',
              fontSize: '0.86rem',
              fontFamily: 'Montserrat',
            },
          },
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        yaxis: {
          title: {
            text: 'ETB',
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter(val) {
              return `${val} Br`
            },
          },
        },
        // axisTicks: {
        //   show: false,
        // },
        // axisBorder: {
        //   show: false,
        // },
        // legend: {
        //   show: false,
        // },
        dataLabels: {
          enabled: false,
        },
      },
      series: [{
        name: 'Net Profit',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      }, {
        name: 'Expenses',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      }],
    }
  },
  computed: {
    ...mapGetters('home', [
      'totalMoneyOutstandingInThisMonth',
      'totalMoneyCollectedInThisMonth',
      'totalMoneyOutstandingInPreviousMonth',
      'totalMoneyCollectedInPreviousMonth',
    ]),
    ...mapState({
      homeDataModel: state => state.home.homeDataModel,
    }),
  },
  watch: {
    homeDataModel(v) {
      this.series[0].data = [ // incomeData
        v.propertyRevenue.propertyIncome.filter(o => o.InMonth === 1).length > 0 ? v.propertyRevenue.propertyIncome.filter(o => o.InMonth === 1)[0].totalAmount : 0, // 1
        v.propertyRevenue.propertyIncome.filter(o => o.InMonth === 2).length > 0 ? v.propertyRevenue.propertyIncome.filter(o => o.InMonth === 2)[0].totalAmount : 0, // 2
        v.propertyRevenue.propertyIncome.filter(o => o.InMonth === 3).length > 0 ? v.propertyRevenue.propertyIncome.filter(o => o.InMonth === 3)[0].totalAmount : 0, // 3
        v.propertyRevenue.propertyIncome.filter(o => o.InMonth === 4).length > 0 ? v.propertyRevenue.propertyIncome.filter(o => o.InMonth === 4)[0].totalAmount : 0, // 4
        v.propertyRevenue.propertyIncome.filter(o => o.InMonth === 5).length > 0 ? v.propertyRevenue.propertyIncome.filter(o => o.InMonth === 5)[0].totalAmount : 0, // 5
        v.propertyRevenue.propertyIncome.filter(o => o.InMonth === 6).length > 0 ? v.propertyRevenue.propertyIncome.filter(o => o.InMonth === 6)[0].totalAmount : 0, // 6
        v.propertyRevenue.propertyIncome.filter(o => o.InMonth === 7).length > 0 ? v.propertyRevenue.propertyIncome.filter(o => o.InMonth === 7)[0].totalAmount : 0, // 7
        v.propertyRevenue.propertyIncome.filter(o => o.InMonth === 8).length > 0 ? v.propertyRevenue.propertyIncome.filter(o => o.InMonth === 8)[0].totalAmount : 0, // 8
        v.propertyRevenue.propertyIncome.filter(o => o.InMonth === 9).length > 0 ? v.propertyRevenue.propertyIncome.filter(o => o.InMonth === 9)[0].totalAmount : 0, // 9
        v.propertyRevenue.propertyIncome.filter(o => o.InMonth === 10).length > 0 ? v.propertyRevenue.propertyIncome.filter(o => o.InMonth === 10)[0].totalAmount : 0, // 10
        v.propertyRevenue.propertyIncome.filter(o => o.InMonth === 11).length > 0 ? v.propertyRevenue.propertyIncome.filter(o => o.InMonth === 11)[0].totalAmount : 0, // 11
        v.propertyRevenue.propertyIncome.filter(o => o.InMonth === 12).length > 0 ? v.propertyRevenue.propertyIncome.filter(o => o.InMonth === 12)[0].totalAmount : 0, // 12

      ]
      this.series[1].data = [ // expenseData
        v.propertyRevenue.propertyExpense.filter(o => o.InMonth === 1).length > 0 ? v.propertyRevenue.propertyExpense.filter(o => o.InMonth === 1)[0].totalAmount : 0, // 1
        v.propertyRevenue.propertyExpense.filter(o => o.InMonth === 2).length > 0 ? v.propertyRevenue.propertyExpense.filter(o => o.InMonth === 2)[0].totalAmount : 0, // 2
        v.propertyRevenue.propertyExpense.filter(o => o.InMonth === 3).length > 0 ? v.propertyRevenue.propertyExpense.filter(o => o.InMonth === 3)[0].totalAmount : 0, // 3
        v.propertyRevenue.propertyExpense.filter(o => o.InMonth === 4).length > 0 ? v.propertyRevenue.propertyExpense.filter(o => o.InMonth === 4)[0].totalAmount : 0, // 4
        v.propertyRevenue.propertyExpense.filter(o => o.InMonth === 5).length > 0 ? v.propertyRevenue.propertyExpense.filter(o => o.InMonth === 5)[0].totalAmount : 0, // 5
        v.propertyRevenue.propertyExpense.filter(o => o.InMonth === 6).length > 0 ? v.propertyRevenue.propertyExpense.filter(o => o.InMonth === 6)[0].totalAmount : 0, // 6
        v.propertyRevenue.propertyExpense.filter(o => o.InMonth === 7).length > 0 ? v.propertyRevenue.propertyExpense.filter(o => o.InMonth === 7)[0].totalAmount : 0, // 7
        v.propertyRevenue.propertyExpense.filter(o => o.InMonth === 8).length > 0 ? v.propertyRevenue.propertyExpense.filter(o => o.InMonth === 8)[0].totalAmount : 0, // 8
        v.propertyRevenue.propertyExpense.filter(o => o.InMonth === 9).length > 0 ? v.propertyRevenue.propertyExpense.filter(o => o.InMonth === 9)[0].totalAmount : 0, // 9
        v.propertyRevenue.propertyExpense.filter(o => o.InMonth === 10).length > 0 ? v.propertyRevenue.propertyExpense.filter(o => o.InMonth === 10)[0].totalAmount : 0, // 10
        v.propertyRevenue.propertyExpense.filter(o => o.InMonth === 11).length > 0 ? v.propertyRevenue.propertyExpense.filter(o => o.InMonth === 11)[0].totalAmount : 0, // 11
        v.propertyRevenue.propertyExpense.filter(o => o.InMonth === 12).length > 0 ? v.propertyRevenue.propertyExpense.filter(o => o.InMonth === 12)[0].totalAmount : 0, // 12

      ]
      const chart = this.$refs.realtimeChart
      chart.updateSeries(this.series, true, true)
    },
  },

  methods: {
    getPercentage(nonCollected, collected) {
      // eslint-disable-next-line no-mixed-operators
      const v = Number(collected) / (Number(nonCollected) + Number(collected)) * 100

      // eslint-disable-next-line use-isnan
      return v === NaN
        ? 0
        : v === 0
          ? 100
          : v === Infinity
            ? 100
            : v
    },
  },
}
</script>

<style scoped>

</style>
