<template>
  <b-overlay
    id="overlay-background-2"
    :show="isLoading"
    spinner-variant="primary"
    spinner-small
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-spinner label="Loading..." />
        <p id="cancel-label">
          Fetching Home data Please wait...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-header class="pb-0">
        <b-card-title>Properties</b-card-title>
        <b-dropdown
          v-if="isAddPropertyAllowed"
          variant="link"
          no-caret
          right
          class="chart-dropdown"
          toggle-class="p-0"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="18"
              class="text-body cursor-pointer"
            />
          </template>
          <b-dropdown-item @click="handleAddNewProperty">
            <feather-icon
              icon="PlusIcon"
              size="18"
              class="text-body cursor-pointer"
            />
            Add New Property
          </b-dropdown-item>

        </b-dropdown>
      </b-card-header>
      <b-card-body>
        <div
          v-for="(item, index) in properties"
          :key="index"
        >
          <template v-if="index <= 2">

            <PropertyCard :property-model="item" />
          </template>
        </div>
        <b-button
          v-if="!allowedPropertyId"
          variant="primary-light"
          style="color: var(--primary)"
          class="pl-0"
          @click="isMorePropertiesSidebarActive = true"
        >
          See all properties
        </b-button>
      </b-card-body>
      <AddEditProperty
        :is-add-new-property-sidebar-active.sync="isAddNewPropertySidebarActive"
      />
      <HomePropertiesMore :is-more-properties-sidebar-active.sync="isMorePropertiesSidebarActive" />
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BDropdown, BDropdownItem, BOverlay, BButton, BSpinner,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import HomePropertiesMore from './HomePropertiesMore.vue'
import AddEditProperty from '../../AddEditProperty/AddEditProperty.vue'
import PropertyCard from './PropertyCard.vue'
import { LogicStateStatus } from '@/libs/ajax'
import AuthMixin from '@/mixins/authMixin'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BOverlay,
    PropertyCard,
    BDropdown,
    BButton,
    BDropdownItem,
    BSpinner,
    AddEditProperty,
    HomePropertiesMore,
  },
  mixins: [AuthMixin],
  data() {
    return {
      isAddNewPropertySidebarActive: false,
      isMorePropertiesSidebarActive: false,
    }
  },
  computed: {
    ...mapState({
      properties: state => state.home.properties,
      logicStateStatus: state => state.home.logicStateStatus,
      isLoading: state => state.home.logicStateStatus === LogicStateStatus.loading,
    }),
  },
  methods: {
    handleAddNewProperty() {
      this.isAddNewPropertySidebarActive = true
    },
  },
}
</script>

<style scoped>

</style>
