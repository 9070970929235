<template>
  <b-overlay
    id="overlay-background"
    :show="isLoading"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <p id="cancel-label">
          Please wait...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-header
        class="pb-0"
        style="margin-bottom: 0.5rem;"
      >
        <b-card-title>This Month</b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row>
          <!-- <b-col
            cols="6"
            class="border-right d-flex align-items-between align-items-start flex-column py-1"
          >

            <h3 class="font-weight-bolder mb-0 text-success">
              76400
            </h3>
            <b-card-text class="text-muted mb-0">
              Rent Revenue
            </b-card-text>
          </b-col> -->

          <b-col
            cols="6"
            class="border-right d-flex align-items-between align-items-start flex-column py-1"
          >

            <b-media no-body>
              <b-media-aside

                class="mr-1"
              >
                <b-avatar
                  size="40"
                  variant="success"
                >
                  <feather-icon
                    size="26"
                    icon="ArrowDownLeftIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0 text-success">
                  Br {{ homeDataModel.thisMonthRevenue }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Rent Revenue
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
            cols="6"
            class="d-flex align-items-between align-items-start flex-column py-1"
          >

            <b-media no-body>
              <b-media-aside

                class="mr-1"
              >
                <b-avatar
                  size="40"
                  variant="danger"
                >
                  <feather-icon
                    size="26"
                    icon="ArrowDownRightIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0 text-danger">
                  Br {{ homeDataModel.thisMonthExpense }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Expenses
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="gradient-primary"
          style="margin-top: 0.5rem;"
          @click="$router.push({name: 'report'})"
        >
          View Reports
        </b-button>
      </b-card-body>
    </b-card>
    <PropertyRevenue />
  </b-overlay>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BCol, BRow, BCardText, BButton, BMedia, BMediaAside, BAvatar, BMediaBody, BOverlay,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'
import { LogicStateStatus } from '@/libs/ajax'
import PropertyRevenue from './PropertyRevenue.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    BCol,
    BRow,
    BButton,
    BOverlay,
    PropertyRevenue,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isReportFilterSidebarActive: false,
    }
  },
  computed: {
    ...mapState({
      homeDataModel: state => state.home.homeDataModel,
      logicStateStatus: state => state.home.logicStateStatus,
      isLoading: state => state.home.logicStateStatus === LogicStateStatus.loading,
    }),
  },
}
</script>

<style scoped>

</style>
